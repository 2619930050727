import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "reactstrap"
import styled from "styled-components"
import { Link } from "gatsby"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Container fluid={true}>
      <Row>
        <Col className="about-content py-4">
          <FourOhFour>
            <h1>Sorry... you've gone the wrong way</h1>
            <ShortLine />
            <p>
              Did a search engine bring you here? Or maybe you mispelled the
              URL?
            </p>
            <p>You just landed on a page that doesn&#39;t exist.</p>
            <h2>
              The article you were searching for might still be here
              somewhere
            </h2>
            <p>
              We might have moved it to a different spot or change the URL name.
            </p>
            <ShortLine />
            <p>
              <b>
                Click <Link to="/learn/">here</Link> to find what you were
                looking for.
              </b>
            </p>
          </FourOhFour>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default NotFoundPage

const ShortLine = styled.hr`
  width: 75%;
`
const FourOhFour = styled.div`
  max-width: 665px;
  margin: auto;
  padding-bottom: ;
`
